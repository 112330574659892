<script setup lang="ts">
import { AppButton, AppTypography } from '@/components';

const props = withDefaults(
  defineProps<{
    title: string;
    subtitle?: string;
    description?: string;

    showButton?: boolean;
    buttonLabel?: string;
    buttonColor?: string;

    loading?: boolean;
  }>(),
  {
    subtitle: '',
    description: '',

    showButton: true,
    buttonLabel: 'Save',
    buttonColor: 'primary',

    loading: false,
  }
);

const emits = defineEmits<{
  (e: 'submit'): void;
}>();

const onClick = (): void => {
  emits('submit');
};
</script>

<template>
  <div
    class="app-form-header d-flex flex-column"
    :class="{
      'align-center': !showButton,
    }"
  >
    <div
      class="d-flex"
      :class="{
        'align-center': !subtitle,
        'justify-space-between': showButton,
        'justify-center': !showButton,
      }"
    >
      <div class="app-form-header__header">
        <AppTypography type="title-1" class="app-form-header__title flex-grow-1">{{ title }}</AppTypography>
        <AppTypography v-if="subtitle" type="headline-1" class="app-form-header__subtitle">
          {{ subtitle }}
        </AppTypography>
      </div>
      <AppButton
        v-if="showButton"
        variant="text"
        :color="buttonColor"
        :disabled="loading"
        class="app-form-header__btn"
        @click="onClick"
        >{{ buttonLabel }}</AppButton
      >
    </div>
    <AppTypography
      v-if="description"
      type="subheadline-2"
      class="app-form-header__description"
      :class="{
        'mt-2': !showButton,
      }"
      >{{ description }}</AppTypography
    >
  </div>
</template>

<style lang="scss" scoped>
@use '~/assets/scss/fonts';

.app-form-header {
  :deep(.v-btn) {
    .v-btn__content {
      @include fonts.font-headline-1;
      font-size: 20px;
    }
  }
}
</style>
